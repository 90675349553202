





























































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import CommunityUser from '@/models/graphql/CommunityUser';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VideoComponent from '@/components/VideoComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import RouteHelper from '@/utils/helpers/RouteHelper';
import { State } from 'vuex-class';

/* eslint-disable no-underscore-dangle */

@Component({
  components: {
    AvatarSoloWidget,
    ButtonIconComponent,
    ButtonComponent,
    VideoComponent,
    FontAwesomeComponent,
    RecommendationBadgeComponent,
  },
})
export default class ExhibitorPortalUserHeaderWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseWidget,
) {
  @State
  private readonly unifyDomain!: string;

  private showVideoPlayer = false;

  get user(): CommunityUser | null {
    return this.one ? CommunityUser.hydrate(this.one) : null;
  }

  private get logoSrc(): string | null {
    if (this.user) {
      return this.user.profilePicture;
    }
    return null;
  }

  private get bannerSrc(): string {
    if (this.user?.bannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.user.bannerFileResource, 'w1110')})`;
    }
    if (this.community.defaultUserBannerFileResource) {
      return `url(${FileResourceHelper.getFullPath(this.community.defaultUserBannerFileResource, 'w1110')})`;
    }
    return 'url(/img/banner/empty.svg)';
  }

  private get videoPresentationUrl(): string | null {
    if (this.user?.videoPresentationS3Url) {
      return this.user.videoPresentationS3Url;
    }
    return null;
  }

  private get followers(): string {
    if (this.user?._followerCount) {
      if (this.isMobile) {
        return `${this.user._followerCount}`;
      }
      return `${this.$tc('actions.followers', 0, { followersCount: this.user._followerCount })}`;
    }
    if (this.isMobile) {
      return '0';
    }
    return `${this.$tc('actions.followers', 0, { followersCount: 0 })}`;
  }

  private get connectionsMessage(): string | null {
    if (this.user?._connectionCount) {
      return `${this.user._connectionCount} ${this.$t('actions.connections')}`;
    }
    return null;
  }

  private get mutual(): string | null {
    if (this.user?._sharedConnectionCount) {
      return `${this.user._sharedConnectionCount} ${this.$t('actions.mutual')}`;
    }
    return null;
  }

  private get mutualPath(): string {
    return RouteHelper
      .externalNavigationBetweenPortals(
        this.unifyDomain,
        'member-connections',
        {
          memberId: this.user?.uid as string,
          query: 'page=1&toggles[mutuals]=true',
        },
      );
  }

  private get connectionPath(): string {
    return RouteHelper
      .externalNavigationBetweenPortals(
        this.unifyDomain,
        'member-connections',
        { memberId: this.user?.uid as string },
      );
  }

  created(): void {
    if (this.payload
        && 'entityCode' in this.payload
        && this.payload.entityCode) {
      const uid = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'communityUser',
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
            value: { uid },
          },
        }),
        fragmentName: 'communityUserEpUserHeaderFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }
}
